<template>
  <div class="welcome-to-vgang">
    <div class="container">
      <div class="row">
        <div class="welcome-message col-sm-12 col-md-8">
          <h1
            class="title mb-3 green--text Polaris-Text--root Polaris-Text--heading4xl"
          >
            VGANG - Dropshipping platform for eco-friendly products
          </h1>

          <p class="paragraph">
            Find vegan & eco-friendly products form reliable suppliers and scale
            your business instantly without buying inventory, handling shipping
            or returns.
          </p>

          <button
            class="d-flex Polaris-Button Polaris-Button--primary mb-2"
            type="button"
          >
            <span class="Polaris-Button__Content" @click="OpenWindow(true)">
              <span class="Polaris-Button__Text">Create an account</span>
            </span>
          </button>

          <p class="have-account" @click="OpenWindow(false)">
            Do you already have an account?
            <a aria-label="Sign in" class="blue-text">Sign in</a>
          </p>
        </div>
        <div class="chat-box col-sm-12 col-md-4">
          <div class="chat-container">
            <h3 class="chat-title">Let's Chat!</h3>
            <p class="description">
              Book a Meeting with Us for Onboarding Help or to Get Your
              Questions Answered
            </p>
            <a
              aria-label=" Book a Meeting"
              class="blue-text"
              target="_blank"
              href="https://calendly.com/vgang/vgang-for-dropshipers"
            >
              Book a Meeting
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import SupplierProduct from "@/services/SupplierProduct";
import { getVariable } from "@/utils/conditionalStoreVariables";
import createApp from "@shopify/app-bridge";

import { getSessionToken } from "@shopify/app-bridge-utils";

export default {
  data() {
    return {
      userType: "retailer",

      popup: null,
    };
  },

  mounted() {
    const oAuthHost = process.env.VUE_APP_OAUTH_HOST;

    window.addEventListener(
      "message",
      async (event) => {
        if (event.origin == oAuthHost) {
          if (event.data.type === "success") {
            if (event.data.isSignUp) {
              this.$router.push(
                "/sh-retailer/onboarding?host=" + getVariable("host")
              );
            } else {
              this.$router.push("/sh-retailer/list-of-products");
            }
          } else if (event.data.type === "request") {
            const response = await this.initiateAppBridge();

            if (event.data.message === "SignIn AccessToken") {
              this.popup.postMessage(
                {
                  type: "approve",
                  message: "SignIn AccessToken Provided",
                  data: response,
                },
                oAuthHost
              );
            } else if (event.data.message === "SignUp AccessToken") {
              this.popup.postMessage(
                {
                  type: "approve",
                  message: "SignUp AccessToken Provided",
                  data: response,
                },
                oAuthHost
              );
            }
          }
        } else {
          return;
        }
      },
      false
    );
  },
  methods: {
    async initiateAppBridge() {
      const apiKey = process.env.VUE_APP_SHOPIFY_RETAILER_API_KEY;
      const host = await getVariable("host");

      const app = createApp({
        apiKey: apiKey,
        host: host,
      });

      try {
        const response = await getSessionToken(app);

        return response;
      } catch (error) {
        console.log(error);
      }
    },

    async OpenWindow(value) {
      const width = 656;
      const height = 750;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 2.5;
      // const shopifyToken = await getVariable("shopifyToken");
      const oAuthHost = process.env.VUE_APP_OAUTH_HOST;

      const popup = await window.open(
        `${oAuthHost}/sh-retailer-oath/oauth?src=${
          process.env.VUE_APP_SOURCE_RETAILER
        }&src_name=${process.env.VUE_APP_SOURCE_NAME_RETAILER}&signUp=${
          value ? 0 : ""
        }`,
        "_blank",
        `width=${width},height=${height},left=${left},top=${top}`
      );
      this.popup = popup;
    },
  },
};
</script>

<style lang="scss" scoped>
.welcome-to-vgang {
  .blue-text {
    color: #2c6ecb !important;
    text-decoration: underline !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  }
  .welcome-message {
    .title {
      font-size: 24px !important;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: 0em;
      text-align: left;
      color: #05603a !important;

      @include md {
        font-size: 24px !important;
      }
    }

    .btn {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: center;
    }
    .paragraph {
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #344054 !important;
      @include md {
        font-size: 14px !important;
      }
    }
    .have-account {
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;

      color: #344054 !important;
    }
  }

  .chat-box {
    background-color: #edf7ee;
    border-radius: 8px;
    .chat-container {
      padding: 0 8px;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      gap: 21px;
      @include md {
        gap: 14px;
      }
    }
    .chat-title {
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      @include md {
        font-size: 20px !important;
      }
    }

    .description {
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 0 !important;
      color: #475467 !important;
      @include md {
        font-size: 14px !important;
      }
    }
  }
}
</style>
